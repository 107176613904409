import { ACTIONS } from './../constants';

export default (
  state = {
    calendar: {},
    isLoadingCalendar: false,
    programCoordinator: {},
    isLoadingProgramCoordinator: false,
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.SAVE_CALENDAR_INIT: {
      return { ...state, isLoadingCalendar: true, calendar: {}, error: null };
    }
    case ACTIONS.SAVE_CALENDAR_SUCCEDED: {
      const newData = { ...state };
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: newData.calendar,
        error: null
      };
    }
    case ACTIONS.SAVE_CALENDAR_FAILED: {
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: {},
        error: payload
      };
    }
    case ACTIONS.GET_CALENDAR_INIT: {
      return { ...state, isLoadingCalendar: true, calendar: {}, error: null };
    }
    case ACTIONS.GET_CALENDAR_SUCCEDED: {
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: payload,
        error: null
      };
    }
    case ACTIONS.GET_CALENDAR_FAILED: {
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: {},
        error: payload
      };
    }
    case ACTIONS.GET_PROGRAM_COORDINATOR_INIT: {
      return {
        ...state,
        isLoadingProgramCoordinator: true,
        programCoordinator: {},
        error: null
      };
    }
    case ACTIONS.GET_PROGRAM_COORDINATOR_SUCCEDED: {
      return {
        ...state,
        isLoadingProgramCoordinator: false,
        programCoordinator: payload,
        error: null
      };
    }
    case ACTIONS.GET_PROGRAM_COORDINATOR_FAILED: {
      return {
        ...state,
        isLoadingProgramCoordinator: false,
        programCoordinator: {},
        error: payload
      };
    }
    case ACTIONS.SAVE_PROGRAM_COORDINATOR_INIT: {
      return {
        ...state,
        isLoadingProgramCoordinator: true,
        programCoordinator: {},
        error: null
      };
    }
    case ACTIONS.SAVE_PROGRAM_COORDINATOR_SUCCEDED: {
      return {
        ...state,
        isLoadingProgramCoordinator: false,
        programCoordinator: payload,
        error: null
      };
    }
    case ACTIONS.SAVE_PROGRAM_COORDINATOR_FAILED: {
      return {
        ...state,
        isLoadingProgramCoordinator: false,
        programCoordinator: {},
        error: payload
      };
    }
    default: {
      return state;
    }
  }
};
