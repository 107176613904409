export const ACTIONS = {
  GET_CALENDAR_INIT: 'GET_CALENDAR_INIT',
  GET_CALENDAR_SUCCEDED: 'GET_CALENDAR_SUCCEDED',
  GET_CALENDAR_FAILED: 'GET_CALENDAR_FAILED',

  SAVE_CALENDAR_INIT: 'SAVE_CALENDAR_INIT',
  SAVE_CALENDAR_SUCCEDED: 'SAVE_CALENDAR_SUCCEDED',
  SAVE_CALENDAR_FAILED: 'SAVE_CALENDAR_FAILED',

  GET_PROGRAM_COORDINATOR_INIT: 'GET_PROGRAM_COORDINATOR_INIT',
  GET_PROGRAM_COORDINATOR_SUCCEDED: 'GET_PROGRAM_COORDINATOR_SUCCEDED',
  GET_PROGRAM_COORDINATOR_FAILED: 'GET_PROGRAM_COORDINATOR_FAILED',

  SAVE_PROGRAM_COORDINATOR_INIT: 'SAVE_PROGRAM_COORDINATOR_INIT',
  SAVE_PROGRAM_COORDINATOR_SUCCEDED: 'SAVE_PROGRAM_COORDINATOR_SUCCEDED',
  SAVE_PROGRAM_COORDINATOR_FAILED: 'SAVE_PROGRAM_COORDINATOR_FAILED'
};

export const ENDPOINT = {
  SAVE_CALENDAR: '/api/employee/calendar',
  GET_CALENDAR: '/api/employee/calendar',
  SAVE_PROGRAM_COORDINATOR: '/api/program/:programId/program-coordinators',
  GET_PROGRAM_COORDINATOR: '/api/program/:programId/program-coordinators/:programCoordinatorId',
};
